<template>
    <div class="selectTag">
        <div class="navBar">
            <div @click="backPage">
                <svg-icon class="navArrow" iconClass="closeIcon" />
            </div>
            <source>
            <div class="navTitle">选择话题</div>
            <div class="rightBtn">{{tags.length}}/1</div>
        </div>
        <HotTagList />
<!--        <van-tabs class="tagTab" v-model="active" title-active-color="#000" title-inactive-color="#9f9faf">-->
<!--            <van-tab title="关注" name="focusOn">-->
<!--                <FocusOnTagList />-->
<!--            </van-tab>-->
<!--            <van-tab title="推荐" name="recommend">-->
<!--                <HotTagList />-->
<!--            </van-tab>-->
<!--        </van-tabs>-->
    </div>
</template>

<script>
    import HotTagList from "./HotTagList";
    import {mapGetters} from "vuex";
    // import FocusOnTagList from "./FocusOnTagList";
    export default {
        computed: {
            ...mapGetters({
                tags: 'tags'
            }),
        },
        components: {
            HotTagList,
            // FocusOnTagList
        },
        data() {
            return {
                active: "recommend"
            }
        },
        methods: {
            // 返回
            backPage() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .selectTag {
        height: 100%;

        .navBar {
            height: 44px;
            padding: 0 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            //border-bottom: 1px solid #e6e6e6;

            .navArrow {
                width: 17px;
                height: 17px;
            }

            .navTitle {
                flex: 2;
                text-align: center;
                font-size: 18px;
                color: #000;
            }

            .rightBtn {
                width: 17px;
                height: 17px;
            }
        }

        .tagTab {
            padding: 0 12px;
            box-sizing: border-box;

            /deep/ .van-tab__text {
                font-size: 18px;
            }

            /deep/ .van-tab {
                // width: 54px;
                flex: none;
                margin-right: 36px;
            }
            /deep/ .van-tabs__nav {
                padding-bottom: 10px;
            }

            /deep/ .van-tabs__line {
                width: 24px;
                height: 3px;
                background: #000;
                // bottom: 20px;
            }
        }
    }
</style>