<template>
    <div class="tagList">
        <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
            @onRefresh="onRefresh" :isHigehtMax="true" :isNoData="isNoData" :error="error">
            <div class="tagItem" v-for="item in list" :key="item.id">
                <div class="tagImg">
                    <ImgDecypt :src="item.coverImg" :key="item.coverImg" />
                </div>
                <div class="tagInfo ellipsis">
                    <div class="tagName">#{{item.name}}</div>
                    <div class="tagDesc">话题总播放次数:{{item.playCount | watchCount}}</div>
                </div>
                <div class="selectBtn" :class="{isSelect: item.isSelect}" @click="selectTag(item)">
                    {{item.isSelect ? '已选' : '选择'}}</div>
            </div>
        </PullRefresh>
    </div>
</template>

<script>
    import {
        queryHotspotWonderList
    } from "@/api/community";
    import ImgDecypt from "@/components/ImgDecypt";
    import PullRefresh from "@/components/PullRefresh";
    import {
        mapGetters
    } from "vuex";
    import {
        Toast
    } from 'vant';
    export default {
        components: {
            ImgDecypt,
            PullRefresh
        },
        computed: {
            ...mapGetters({
                tags: 'tags'
            }),
        },
        data() {
            return {
                list: [],
                pageNumber: 1,
                pageSize: 10,
                loading: true,
                refreshing: false,
                finished: false,
                error: false,
                isNoData: false,
                selectedList: [],
            }
        },
        created() {
            this.selectedList = [].concat(this.tags)
            this.getList();
        },
        methods: {
            // 查询列表
            async getList(type) {
                let req = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    uid: this.$route.query.uid ? this.$route.query.uid : undefined
                    // uid: 224411
                }
                try {
                    let res = await this.$Api(queryHotspotWonderList, req);
                    this.loading = false;
                    this.refreshing = false;
                    if (res.code === 200) {
                        let list = res.data.list || [];
                        if (this.tags && this.tags.length > 0) {
                            list.forEach(i => {
                                if (this.checkSelect(i)) {
                                    i.isSelect = true;
                                } else {
                                    i.isSelect = false;
                                }
                            });
                        } else {
                            list.forEach(i => {
                                i.isSelect = false
                            });
                        }
                        if (type === 'refresh') {
                            this.list = list;
                        } else {
                            this.list = this.list.concat(list);
                        }
                        if (this.list.length <= 0) {
                            this.isNoData = true
                        }
                        if (!res.data.hasNext) {
                            this.finished = true;
                            return;
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                }


            },
            checkSelect(i) {
                let sta = this.tags.find((j) => {
                    return j.id == i.id;
                })
                return sta;
            },
            // 上啦加载
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                this.getList();
            },
            // 下拉刷新
            onRefresh() {
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.loading = true;
                this.getList("refresh");
            },
            selectTag(item) {
                // if(!item.isSelect){
                //     if(this.selectedList.length > 0){
                //         this.$toast('只允许选择一个话题');
                //         return;
                //     }
                // }
                this.list.forEach((i, j) => {
                    i.isSelect = false;
                });
                this.selectedList = [];
                
                let hasTag = false;
                item.isSelect = !item.isSelect;
                if (this.selectedList.length > 0) {
                    this.selectedList.forEach((i, j) => {
                        if (i.id == item.id) {
                            this.selectedList.splice(j, 1);
                            hasTag = true;
                        }
                    })
                }
                if (!hasTag) {
                    this.selectedList.push(item);
                }
                this.$store.commit('commnity/ADD_TAGS', this.selectedList);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tagList {
        height: calc(100vh - 88px);
        color: #000;
        padding: 0 16px;
        box-sizing: border-box;

        .tagItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;
            padding: 14px 12px;
            background: #FAFAFA;

            .tagImg {
                width: 38px;
                height: 38px;
                border-radius: 4px;
                
                /deep/ .van-image__img{
                    border-radius: 4px;
                }
            }

            .tagInfo {
                margin: 0 12px;
                flex: 2;
                
                .tagName{
                    font-size: 16px;
                    color: #333;
                    font-weight: 500;
                }

                .tagDesc {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    color: #666;
                    font-size: 10px;
                }
            }

            .selectBtn {
                width: 50px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                border-radius: 25px;
                font-size: 12px;
                color: #333;
                background: #FBD13D;
            }

            .isSelect {
                font-size: 12px;
                background: #F2F2F2;
            }
        }
    }
</style>